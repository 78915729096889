export function extendIsOnlyNumbers(value: string) {
  return !!value.match(/[0-9\u06F0-\u06F9]/g);
}

export function extendIsProperPhoneLengthCheck(value: string) {
  return value.length === 11;
}

export function extendIsPhoneProperCheck(value: string) {
  if (!value) {
    return false;
  }
  const ACCEPTED_PREFIX = ['010', '011', '012', '015'];
  const numericalRe = /^\d+$/g;
  const firstThree = value.substring(0, 3);
  return !!value.match(numericalRe) && ACCEPTED_PREFIX.includes(firstThree);
}

export function extendIsPhone(value: string) {
  return extendIsPhoneProperCheck(value) && extendIsOnlyNumbers(value) && extendIsProperPhoneLengthCheck(value);
}
